.admin-zone {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 10%;
  padding-bottom: 64px !important;
}

.table-list-users tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-list-user {
  min-width: 1000px;
}

.table-list-users thead th,
.table-list-users tbody td {
  @apply text-left p-2;
}

.table-list-user thead th {
  @apply border-b-1  border-black;
}

.table-list-users-container {
  overflow: auto;
}

.btn-admin-zone {
  @apply flex items-center bg-blue-500 hover:bg-blue-800 text-white rounded-md p-4;
}


.btn-admin-zone span {
  @apply mr-2;
}

.small {
  padding: 8px !important;
  font-size: 12px !important;
}