/*@import 'tailwindcss/base';*/
/*@import 'tailwindcss/components';*/
/*@import 'tailwindcss/utilities';*/

/*body {*/
/*    -webkit-print-color-adjust: exact !important;*/
/*}*/

/*nav li a.active {*/
/*    border-color: #7f9cf5;*/
/*}*/

/*.z-99999 {*/
/*    z-index: 99999 !important;*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*    .base_card_auth {*/
/*        width: 100% !important;*/
/*    }*/
/*}*/

/*.f-none {*/
/*    float: none*/
/*}*/

/*.break-after-avoid {*/
/*    page-break-after: avoid;*/
/*}*/

/*.break-before-always {*/
/*    page-break-before: always;*/
/*}*/

/*.break-inside-always {*/
/*    page-break-inside: avoid;*/
/*}*/

/*.base_card_auth {*/
/*    width: 500px;*/
/*}*/

/*.card_after:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    !* z-index: 999999999999; *!*/
/*    top: 0;*/
/*    width: 100%;*/
/*    border-radius: 16px;*/
/*    background: rgba(0, 0, 0, 0.5);*/
/*    height: 100%;*/
/*    left: 0;*/
/*    right: 0;*/
/*    !* opacity: 0.3; *!*/
/*}*/

/*.arrow-left {*/
/*    content: ' ';*/
/*    position: absolute;*/
/*    left: -19px;*/
/*    width: 0;*/
/*    height: 0;*/
/*    border-top: 20px solid transparent;*/
/*    border-bottom: 20px solid transparent;*/
/*    border-right: 20px solid #FFFFFF;*/
/*}*/

/*h3 {*/
/*    @apply font-semibold !important;*/
/*}*/

/*.lg\:prose-xl img {*/
/*    margin: unset !important;*/
/*}*/


/*.bottomDynamic:after {*/
/*    content: ''; !* This is necessary for the pseudo element to work. *!*/
/*    display: block; !* This will put the pseudo element on its own line. *!*/
/*    margin: 0 auto; !* This will center the border. *!*/
/*    width: 33.33%; !* Change this to whatever width you want. *!*/
/*    border-bottom: 4px solid #3b82f6; !* This creates the border. Replace black with whatever color you want. *!*/
/*}*/

/*.min-content {*/
/*    height: min-content;*/
/*}*/

/*.tooltip {*/
/*    @apply hidden absolute;*/
/*}*/

/*.logo {*/
/*    width: 161px !important;*/
/*    height: 40px !important;*/
/*    max-width: inherit;*/
/*}*/

/*.has-tooltip:hover .tooltip {*/
/*    @apply block;*/
/*    z-index: 9999999999;*/
/*    width: 300px;*/
/*}*/

/*.card {*/
/*    @apply bg-white p-8 rounded-lg;*/
/*}*/

/*.card-secondary {*/
/*    @apply bg-blue-darken p-8 shadow-2xl rounded-lg text-white;*/
/*}*/

/*h3 {*/
/*    @apply text-xl;*/
/*}*/

/*!* a {*/
/*  color: black;*/
/*} *!*/

/*body span {*/
/*    font-family: 'Poppins', sans-serif !important;*/
/*}*/

/*.font-poppins {*/
/*    font-family: 'Poppins', sans-serif;*/
/*}*/

/*!**/
/*.font-poppins-semibold {*/
/*  font-family: "Poppins", sans-serif;*/
/*} *!*/
