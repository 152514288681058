@media screen and (max-width: 768px) {
    .modal-content {
        max-height: calc(200vh - 100px);
    }

    .force_resize_mobile {
        max-height: 300px !important;
    }
}

.modal-overlay {
    position: fixed;
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.overlay-loading {
    position: fixed;
    z-index: 9999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.modal-content {
    background: white;
    max-width: 1200px;
    /* max-height: calc(100vh - 100px); */
}

.fullHeight-modal {
    min-height: calc(100vh - 100px);
}

.modal-icon {
    cursor: pointer;
    right: 200px;
    top: 0;
}
