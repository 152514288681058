@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'swiper/swiper.min.css';
@import 'swiper/modules/navigation/navigation.min.css';
@import '../stylesheets/global.css';

@import '../stylesheets/pagination/pagination.css';
@import '../stylesheets/table/table.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


body {
  height: 100%;
  print-color-adjust: exact !important;
}

body.waiting * {
  cursor: wait !important;
}

nav li a.active {
  border-color: #7f9cf5;
}

.z-99999 {
  z-index: 99999 !important;
}

.z-10000 {
  z-index: 10000 !important;
}

@media screen and (max-width: 768px) {
  .base_card_auth {
    width: 100% !important;
  }
}

.f-none {
  float: none;
}

.break-after-avoid {
  page-break-after: avoid;
}

.break-after-always {
  page-break-always: always;
}

.break-before-always {
  page-break-before: always;
}

.break-inside-always {
  page-break-inside: avoid;
}

.base_card_auth {
  width: 500px;
}

.card_after:after {
  content: '';
  position: absolute;
  /* z-index: 999999999999; */
  top: 0;
  width: 100%;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  right: 0;
  /* opacity: 0.3; */
}

/* h3 {
  @apply font-semibold !important;
} */

.lg\:prose-xl img {
  margin: unset !important;
}

.bottomDynamic:after {
  content: ''; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 33.33%; /* Change this to whatever width you want. */
  border-bottom: 4px solid #3b82f6; /* This creates the border. Replace black with whatever color you want. */
}

.min-content {
  height: min-content;
}

.tooltip {
  @apply hidden absolute;
}

.logo {
  width: auto;
  height: 26px;
  max-width: inherit;
}

@media (min-width: 1280px) {
  .logo {
    width: 161px;
    height: 40px;
  }
}

.has-tooltip:hover .tooltip {
  @apply block;
  z-index: 9999999999;
  width: 300px;
}

.card {
  @apply bg-white p-8 rounded-lg;
}

.card-secondary {
  @apply bg-blue-darken p-8 shadow-2xl rounded-lg text-white;
}

h3 {
  @apply font-semibold;
}

/* h3 {
  @apply text-xl;
} */

/* a {
  color: black;
} */

body span {
  font-family: 'Poppins', sans-serif !important;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

/*
.font-poppins-semibold {
  font-family: "Poppins", sans-serif;
} */

/* Made by Pak for connection / disconnection messages

*/

.message-connexion {
  z-index: 999999 !important;
  position: fixed;
  left: 50%;
  top: 0;
  font-size: 2em;
  font-weight: bold;
  padding: 1.5em;
  transform: translate(-50%, -100%);
  transition: transform 0.25s;
}

#offline {
  background: #e91e63;
  color: white;
}

#online {
  background: #4caf50;
  color: white;
}

body.offline #offline,
body.online #online {
  transform: translate(-50%, 0);
}

/* end disconnection css */

.msLegend {
  min-width: 20px;
  min-height: 20px;
}

.msLegendContainer {
  display: none;
}

.msLegendContainer.active {
  display: block;
}

.banner-section {
  height: 48px;
}

.qk-modal {
  height: calc(100vh - 100px) !important;
  max-height: calc(100vh - 100px) !important;
  overflow: hidden;
}

.btn-bottom-qk {
  -webkit-box-shadow: 2px -12px 19px -18px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px -12px 19px -18px rgba(0, 0, 0, 0.75);
  box-shadow: 2px -12px 19px -18px rgba(0, 0, 0, 0.75);
}

.qk-modal {
  height: calc(100vh - 100px) !important;
  max-height: calc(100vh - 100px) !important;
  overflow: hidden;
}

.qk-modal {
  height: calc(100vh - 100px) !important;
  max-height: calc(100vh - 100px) !important;
  overflow: hidden;
}

.my-popup .leaflet-popup-tip {
  display: none !important;
}

.my-nav-qc {
  top: 50%;
  transform: translate(0, -50%);
  background-color: rgba(255, 255, 255, 0.528);
  padding: 2px;
  border-radius: 100%;
}

.custom-cluster-marker-has-selelected-program .deco {
  background: #00c32786 !important;
}

.custom-cluster-marker-has-selelected-program::after {
  background: #00ca28 !important;
}

.custom-cluster-marker {
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.custom-cluster-marker .deco {
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 500;
  background: #4cb1f586;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.custom-cluster-marker .label {
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 14px;
}

.custom-cluster-marker::after {
  content: '';
  clear: both;
  display: block;
  width: 24px;
  height: 24px;
  background: #4cb2f5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  border-radius: 100%;
}

.leaflet-bottom .leaflet-control-zoom {
  margin-bottom: 80px !important;
}

.swiper-slide {
  height: auto !important;
}

.swiper-wrapper {
  align-items: stretch !important;
}

/* set in front */
.have-selected-icon-cluster {
  z-index: 9999 !important;
}

.annotationLayer,
.textLayer {
  display: none !important;
}
