@import './custom-autocomplete/CustomAutocomplete.css';
@import './select-all-or-cibler/selectAllOrCibler.css';
@import './formulaire-cibler/formulaireCibler.css';
@import './filter-marche-immobilier/filterMarcheImmobilier.css';
@import './adminZone.css';
@import './autocomplete.css';

.leaflet-div-icon {
  height: 24px !important;
  width: 24px !important;
  border: none !important;
  background-color: transparent !important;
}

.equipement-icon {
  transform: translate(-30%, -50%);
}

.equipement-icon img {
  width: auto !important;
  height: 20px !important;
}

.shadow-next-container {
  -webkit-box-shadow: -7px -7px 33px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -7px -7px 33px -6px rgba(0, 0, 0, 0.2);
  box-shadow: -7px -7px 33px -6px rgba(0, 0, 0, 0.2);
}

.cgv-wrapper canvas {
  width: 100% !important;
  height: auto !important;
}

.item-account-modal-form-admin-user:last-child {
  border-bottom: none !important;
}

.checkbox-program div {
  cursor: pointer;
}

.my-table tbody {
  display: block;
  overflow: auto;
}

.my-table thead,
.my-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.th-content {
  height: 53px;
  min-height: 53px;
  max-height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.th-content {
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.swal-button--reset {
  background-color: rgb(255, 56, 56) !important;
  color: white !important;
}

.card-container-new-search {
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 32px;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 768px) {
  .card-container-new-search {
    position: fixed;
    z-index: 50;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#error_explanation {
  margin-top: 24px;
  padding: 4px;
  border-radius: 8px;
  background-color: rgba(255, 0, 0, 0.367);
  color: white;
}

.new-passord {
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  display: flex !important;
  justify-content: center !important;
}

.swal-button--confirm {
  background-color: #2c5282 !important;
}

.input-tag {
  width: 100% !important;
}

.tag-share-edm {
  display: flex !important;
  width: 100% !important;
  padding: 8px !important;
  justify-content: space-between !important;
}
