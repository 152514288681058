.custom-autocomplete-container {
  width: 100%;
  height: 48px;
}

.custom-autocomplete-item-container div:last-child {
  border-bottom: none;
}

.custom-autocomplete-container input[type='text']:focus {
  box-shadow: 0 0 0 rgb(255, 255, 255);
}
