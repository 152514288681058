
.my-table thead th {
  border-bottom: 1px solid rgb(137, 137, 137);
}

.my-table {
  border-collapse: collapse;
}

.my-table td {
  padding: 8px 16px;
  border-bottom: 1px solid rgb(137, 137, 137);
}

.my-table .th-content {
  margin: -2px -2px !important;
  padding: 8px 16px;
}

.table-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
