.storybook-button {
    @apply rounded-lg;
    border: 0;
    display: inline-block;
    line-height: 1;
}

.testH1 {
    width: 121px;
    height: 32px;
    font-size: 23px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #092c4b;
}

.storybook-button--primary {
    /* @apply p-4; */
    background-color: #1ba3ef;
}

.storybook-button--secondary {
    color: #333;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}

.storybook-button--small {
    font-size: 12px;
    padding: 10px 16px;
}

.storybook-button--medium {
    font-size: 14px;
    padding: 11px 20px;
}

.storybook-button--large {
    font-size: 16px;
    padding: 12px 24px;
}

.gradient-button {
    background: linear-gradient(-45deg, #4b6cb7, #182848, #6b8dd6, #8e37d7);
    background-size: 400% 400%;
    transition: all 0.3s ease-in-out;
    display: inline-block;
}

.gradient-button:hover {
    background-position: right center; /* change the direction of the gradient */
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.gradient-button {
    animation: gradientBG 15s ease infinite;
}
